import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FoodListResponse } from '../objects/responses/foodListResponse';
import { CategoryListResponse } from '../objects/responses/CategoryListResponse';
import { Food } from '../objects/Food';
import { Profile } from '../objects/Profile';
import { ProfileService } from './profile.service';
import { SearchFilter } from '../objects/searchFilter';
import { Restaurant } from '../objects/Restaurant';
import { SearchMapInfo } from '../objects/SearchMapInfo';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchURL = 'https://matthewmastell.com:3002/search';
  profile: Profile;
  restaurants: Restaurant[];
  foodList: Food[];
  private search = [];

  private currFoodList = new BehaviorSubject<Food[]>(null);
  newFoodList = this.currFoodList.asObservable();

  private currRestaurantList = new BehaviorSubject<Restaurant[]>(null);
  newRestaurantList = this.currRestaurantList.asObservable();

  private currSearchMapInfo = new BehaviorSubject<SearchMapInfo>(null);
  newSearchMapInfo = this.currSearchMapInfo.asObservable();

  private currSearch = new BehaviorSubject<string>('');
  newSearch = this.currSearch.asObservable();

  private currSearchFilters = new BehaviorSubject<SearchFilter>({ radius: 0, voteTime: 'All Time' });
  newSearchFilters = this.currSearchFilters.asObservable();

  //the validator is a boolean which tells us whether or not the search returned a result.
  private currSearchValidator = new BehaviorSubject<boolean>(false);
  newSearchValidator = this.currSearchValidator.asObservable();

  constructor(private http: HttpClient, private profileService: ProfileService) {
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
  }

  Search(txtSearch, filter) {
    const sendData = new FormData();
    sendData.append('search', txtSearch);
    sendData.append('voteTime', filter.voteTime);
    sendData.append('lat', this.profile.latitude.toString());
    sendData.append('long', this.profile.longitude.toString());
    if (filter.radius > 0) {
      sendData.append('radius', filter.radius.toString());
    }
    this.http.post<FoodListResponse | CategoryListResponse>(this.searchURL, sendData, { responseType: 'json' }).subscribe(data => {
      this.currSearch.next(txtSearch);
      this.currSearchFilters.next(filter);
      this.currSearchValidator.next(false);
      switch (data.type) {
        case 'food':
          this.restaurants = data.restaurants as Restaurant[];
          this.foodList = data.response as Food[];
          this.currFoodList.next(this.compareFoods(data.response as Food[]));
          this.currRestaurantList.next(data.restaurants as Restaurant[]);
          this.updateSearchMapInfo(18);
          break;
        case 'empty':
          this.currSearchValidator.next(true);
          this.currFoodList.next(null);
          break;
      }
    });
  }

  splitString(val) {
    return val.split(" ");
  }

  updateSearchMapInfo(idRestaurant) {
    let newFoodList = [];
    let newRestaurant = null;

    for (var rest of this.restaurants) {
      if (rest.idrestaurant == idRestaurant)
        newRestaurant = rest;

    }
    for (var food of this.foodList) {
      if (food.idrestaurant == idRestaurant)
        newFoodList.push(food);
    }
    var newMapinfo: SearchMapInfo = {
      foods: newFoodList,
      restaurant: newRestaurant
    };

    this.currSearchMapInfo.next(newMapinfo);
  }

  compareFoods(foodlist) {

    let i;
    let j;
    let foodLen;
    let profileFoodLen;
    if (this.profile.foodlikes) {
      for (i = 0, foodLen = foodlist.length; i < foodLen; i++) {
        foodlist[i].isfoodliked = false;
        for (j = 0, profileFoodLen = this.profile.foodlikes.length; j < profileFoodLen; j++) {
          if (Number(this.profile.foodlikes[j].idfood) === Number(foodlist[i].idfood)) {
            foodlist[i].isfoodliked = true;
          }
        }
      }
    }
    return foodlist;
  }
}
