import { Component, OnInit, Input } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { ReviewMessage } from '../objects/ReviewMessage';
declare var $: any;

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() data;
  messageCreated: boolean;
  message: string;
  voted: boolean;
  messages: ReviewMessage[];
  currentrestaurant: number;
  constructor(private profileService: ProfileService) {
    this.messageCreated = true;
    this.message = '';
    this.voted = null;
    this.currentrestaurant = -1;
  }

  ngOnInit() {
    this.currentrestaurant = this.data;
    this.profileService.getRestaurantReviews(this.currentrestaurant).subscribe(data => {
      this.messages = data;
    });
  }

  vote(val) {
    this.voted = val;
    if (this.voted) {
      $('.up-vote a').css('background-image', 'url(../../assets/images/thumb-up-active.png)');
      $('.down-vote a').css('background-image', 'url(../../assets/images/thumb-up.png)');
    } else {

      $('.up-vote a').css('background-image', 'url(../../assets/images/thumb-up.png)');
      $('.down-vote a').css('background-image', 'url(../../assets/images/thumb-up-active.png)');
    }
  }

  submit() {
    this.profileService.RateRestaurant(this.voted, this.currentrestaurant, this.message);
  }

}
