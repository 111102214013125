import { Component, OnInit, ElementRef } from '@angular/core';
import { SearchService } from '../services/search.service';
import { VoteService } from '../services/vote.service';
import { FoodService } from '../services/food.service';
import { Food } from '../objects/Food';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../objects/Profile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-searchpage',
  templateUrl: './searchpage.component.html',
  styleUrls: ['./searchpage.component.scss']
})
export class SearchpageComponent implements OnInit {
  foodList: Food[];
  isEmpty: boolean;
  profile: Profile;
  elRef: ElementRef;
  constructor(private searchService: SearchService, private router: Router, private voteService: VoteService, private profileService: ProfileService,
    private foodService: FoodService) {
    this.isEmpty = false;
  }

  ngOnInit() {
    this.searchService.newFoodList.subscribe(data => {
      this.foodList = data;
    });
    this.searchService.newSearchValidator.subscribe(data => {
      this.isEmpty = data;
    });

    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
  }

  eVote(event, food, index) {
    event.stopPropagation();
    
    if(this.profile.userid){
      this.foodList[index].isfoodliked = true;
      this.foodList[index].foodLikes++;
      this.voteService.vote(food.idfood);
    }else{
      alert("create a profile to vote");
    }

  }

  cantVote(event) {
    event.stopPropagation();
    alert('already voted! Thank you!');
  }

  routeToFoodInfo(element: Element, food: Food, foodIsLiked: boolean): void {
    this.router.navigate(['/foodinfo'], { state: { data: food, foodIsLiked: foodIsLiked } });
  }


}
