import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private cookie: CookieService) {
  }

  setUserCookieLogin(token: string) {
    this.cookie.set('login-token', token);
  }

  getUserCookie(): string {
    return this.cookie.get('login-token');
  }

  deleteCookies() {
    this.cookie.delete('login-token');
  }
}

