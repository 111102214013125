import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from '../app/services/cookies.service';
import { AuthService } from './services/auth.service';
import { ProfileService } from './services/profile.service';
import { Profile } from './objects/Profile';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'stuffed';
  profile: Profile;

  constructor(private router: Router, private cookiesService: CookiesService, private authService: AuthService, private profileService: ProfileService) {
      navigator.geolocation.getCurrentPosition(showPosition.bind(this));
      function showPosition(position) {
        this.profile = {} as Profile;
        this.profile.latitude = position.coords.latitude;
        this.profile.longitude = position.coords.longitude;
        profileService.setProfile(this.profile);
      }
  }

  ngOnInit() {
    this.authService.localAuthSetup();
    this.authService.handleAuthCallback();
    this.router.navigate(['home']);
  }
}