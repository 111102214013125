import { Component, OnInit } from '@angular/core';
import { Food } from '../objects/Food';
import { FoodService } from '../services/food.service';
import { Image } from '../objects/Image';
import { NgImageSlider } from '../objects/NgImageSlider';
import { FoodByRestaurant } from '../objects/responses/foodByRestaurant';
import { ProfileService } from '../services/profile.service';
import { Router } from '@angular/router';
import { Profile } from '../objects/Profile';
import { forkJoin } from 'rxjs';
import { tap, flatMap } from 'rxjs/operators';
import { VoteService } from '../services/vote.service';
declare var $: any;

@Component({
  selector: 'app-restaurantinfo',
  templateUrl: './restaurantinfo.component.html',
  styleUrls: ['./restaurantinfo.component.scss']
})
export class RestaurantinfoComponent implements OnInit {
  foodInfo: Food;
  foodList: FoodByRestaurant;
  profile: Profile;
  idrestaurant: number;
  currentMenuSelected: number;
  sliderImageWidth: 300;
  sliderImageHeight: 100;
  restaurantImages: Image[];
  imageObject: Array<NgImageSlider>;

  constructor(private foodService: FoodService, private profileService: ProfileService, private router: Router, private voteService: VoteService) {
    this.idrestaurant = -1;
    this.foodList = {
      isOpen: true,
      longitude: 0,
      latitude: 0,
      state: "",
      number: "",
      website: "",
      restaurantName: "",
      menuOptions: [],
      food: []
    };
  }

  ngOnInit() {
    this.foodInfo = history.state;
    this.idrestaurant = this.foodInfo.idrestaurant;
    this.imageObject = new Array<NgImageSlider>();

    this.profileService.currProfile.pipe(
      tap(u => {
        this.profile = u;
        console.log(u);
      }),
      flatMap(u => this.foodService.getFoodbyRestaurant(this.foodInfo.idrestaurant, false, null))
    ).subscribe(p => {
      this.foodList = this.compareFoods(p);
      $('#menu-' + this.foodList.menuOptions[0]).addClass("selected");
    });


    this.foodService.getRestaurantImages(this.foodInfo.idrestaurant).subscribe(data => {
      this.restaurantImages = data;

      this.imageObject.push({ image: this.restaurantImages[1].ImagePath, thumbImage: this.restaurantImages[1].ImagePath });
      this.imageObject.push({ image: this.restaurantImages[2].ImagePath, thumbImage: this.restaurantImages[2].ImagePath });
      this.imageObject.push({ image: this.restaurantImages[0].ImagePath, thumbImage: this.restaurantImages[0].ImagePath });
      this.imageObject.push({ image: this.restaurantImages[1].ImagePath, thumbImage: this.restaurantImages[1].ImagePath });
      this.imageObject.push({ image: this.restaurantImages[0].ImagePath, thumbImage: this.restaurantImages[0].ImagePath });
      this.imageObject.push({ image: this.restaurantImages[2].ImagePath, thumbImage: this.restaurantImages[2].ImagePath });
    });
  }

  updateMenu(idmenu) {
    $('#menu-' + this.foodList.menuOptions[0]).removeClass("selected");
    this.foodService.getFoodbyRestaurant(this.foodInfo.idrestaurant, false, idmenu).subscribe(data => {
      this.foodList = data;
      $('#menu-' + idmenu).addClass("selected");
    });
  }

  eVote(event, food, index) {
    event.stopPropagation();
    this.foodList.food[index].isfoodliked = true;
    this.foodList.food[index].foodLikes++;
    this.voteService.vote(this.foodList.food[index].idfood);
  }


  routeToFoodInfo(food: Food, foodIsLiked: boolean): void {
    this.router.navigate(['/foodinfo'], { state: { data: food, foodIsLiked: foodIsLiked } });
  }

  compareFoods(foodlist) {
    let i;
    let j;
    let foodLen;
    let profileFoodLen;

    if (this.profile.foodlikes) {
      for (i = 0, foodLen = foodlist.food.length; i < foodLen; i++) {
        foodlist.food[i].isfoodliked = false;
        for (j = 0, profileFoodLen = this.profile.foodlikes.length; j < profileFoodLen; j++) {
          if (Number(this.profile.foodlikes[j].idfood) === Number(foodlist.food[i].idfood)) {
            foodlist.food[i].isfoodliked = true;
          }
        }
      }
    }
    return foodlist;
  }
}
