import { Component, OnInit, ElementRef } from '@angular/core';
import { VoteService } from '../services/vote.service';
import { FoodService } from '../services/food.service';
import { Food } from '../objects/Food';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../objects/Profile';
import { Router } from '@angular/router';
@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss']
})
export class FoodComponent implements OnInit {

  foodList: Food[];
  profile: Profile;
  elRef: ElementRef;
  constructor(private router: Router, private voteService: VoteService, private profileService: ProfileService,
    private foodService: FoodService) { }

  ngOnInit() {
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
    this.foodService.newFoodList.subscribe(data => {
      if (data != null) {
        this.foodList = data;
      }
    });
    this.foodService.getFoods();
  }

  eVote(event, food, index) {
    event.stopPropagation();
    this.foodList[index].isfoodliked = true;
    this.foodList[index].foodLikes++;
    this.voteService.vote(food.idfood);
  }


  routeToFoodInfo(element: Element, food: Food, foodIsLiked: boolean): void {
      this.router.navigate(['/foodinfo'], { state: { data: food, foodIsLiked: foodIsLiked } });
  }

  cantVote(event) {
    event.stopPropagation();
    alert('already voted! Thank you!');
  }
}
