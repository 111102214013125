import { Component, OnInit } from '@angular/core';
import { Food } from '../objects/Food';
import { FoodService } from '../services/food.service';

@Component({
  selector: 'app-chefinfo',
  templateUrl: './chefinfo.component.html',
  styleUrls: ['./chefinfo.component.scss']
})
export class ChefinfoComponent implements OnInit {
  food: Food[];
  constructor(private foodService: FoodService) { }

  ngOnInit() {
    this.foodService.getFoodbyRestaurant(history.state.data.restaurantID, false, null).subscribe(data => {
      console.log(data);
    });
  }

}
