import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ProfileService } from '../services/profile.service';
import { filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isCTALogin: boolean;
  isLoggedIn: boolean;
  isRegistering: boolean;
  isLoggingIn: boolean;
  emailRegister: string;
  registerUsername: string;
  token: string;
  register: string;
  passwordCheck: string;
  CTALoginSteps: number;
  CTALoginValue: string;
  loginValue: string;
  loginValueAttempt: string;
  CTALoginPlaceHolder: string;

  constructor(private router: Router, private profileService: ProfileService, private authService: AuthService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url != "/home" ) {
        $('.main-container').css('background-color', 'white')
        $('.logo-icon').css('background-image', 'url(../../assets/images/logo-icon-2nd.png)');
      } else {
        $('.logo-icon').css('background-image', 'url(../../assets/images/logo-icon.png)');
        $('.app-header > div').css('background-color', '#f5743d');

      }
    });
  }

  ngOnInit() {
    this.isLoggedIn = false;
    this.CTALoginValue = 'login/register';
    this.profileService.currProfile.subscribe(data => {
      if (data != null) {
        this.isLoggedIn = true;
      }
    });

  }

  login() {
    this.authService.login();

  }

  toggleCTARegister() {
    //this.loginService.tempCheck();
  }
  
  logOut() {
    this.authService.logout();
  }
}
