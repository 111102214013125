import { Component, OnInit } from '@angular/core';
import { SearchService } from '../services/search.service';
import { SearchMapInfo } from '../objects/SearchMapInfo';
import { Food } from '../objects/Food';
import { Router } from '@angular/router';
import { VoteService } from '../services/vote.service';

@Component({
  selector: 'app-search-map-info',
  templateUrl: './search-map-info.component.html',
  styleUrls: ['./search-map-info.component.scss']
})
export class SearchMapInfoComponent implements OnInit {
  mapInfo: SearchMapInfo;

  constructor(private searchService: SearchService, private router: Router, private voteService: VoteService) { }

  ngOnInit() {
    this.searchService.newSearchMapInfo.subscribe(data => {
      this.mapInfo = data;
      console.log(this.mapInfo);
    });
  }

  routeToFoodInfo(element: Element, food: Food, foodIsLiked: boolean): void {
    this.router.navigate(['/foodinfo'], { state: { data: food, foodIsLiked: foodIsLiked } });
  }

  openWebsite() {
    console.log(this.mapInfo.restaurant.website);
    window.open(this.mapInfo.restaurant.website, '_blank');
  }

  eVote(event, food, index) {
    event.stopPropagation();
    this.mapInfo.foods[index].isfoodliked = true;
    this.mapInfo.foods[index].foodLikes++;
    this.voteService.vote(this.mapInfo.foods[index].idfood);
  }

}
