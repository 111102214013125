import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FoodComponent } from './food/food.component';
import { ChefsComponent } from './chefs/chefs.component';
import { ProfileComponent } from './profile/profile.component';
import { RestaurantpageComponent } from './restaurantpage/restaurantpage.component';
import { ChefpageComponent } from './chefpage/chefpage.component';
import { FoodpageComponent } from './foodpage/foodpage.component';
import { SearchpageComponent } from './searchpage/searchpage.component';
import { FoodinfoComponent } from './foodinfo/foodinfo.component';
import { ChefinfoComponent } from './chefinfo/chefinfo.component';
import { RestaurantinfoComponent } from './restaurantinfo/restaurantinfo.component';
import { SearchContainerComponent } from './search-container/search-container.component';
import { ProfileinfoComponent } from './profileinfo/profileinfo.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'food', component: FoodComponent },
  { path: 'chefs', component: ChefsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'restaurantpage', component: RestaurantpageComponent },
  { path: 'chefpage', component: ChefpageComponent },
  { path: 'foodpage', component: FoodpageComponent },
  { path: 'searchpage', component: SearchpageComponent },
  { path: 'foodinfo', component: FoodinfoComponent },
  { path: 'chefinfo', component: ChefinfoComponent },
  { path: 'restaurantinfo', component: RestaurantinfoComponent },
  { path: 'search-container', component: SearchContainerComponent },
  { path: 'profileinfo', component: ProfileinfoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
