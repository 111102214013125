import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profileheader',
  templateUrl: './profileheader.component.html',
  styleUrls: ['./profileheader.component.scss']
})
export class ProfileheaderComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
    const activeLink = document.querySelectorAll('.profile-pages > i');
    console.log(this.router.url);
    switch (this.router.url) {
      case '/foodpage':
        activeLink[0].classList.add('setActive');
        break;
      case '/chefpage':
        activeLink[1].classList.add('setActive');
        break;
      case '/restaurantpage':
        activeLink[2].classList.add('setActive');
        break;
      case '/profile':
        activeLink[3].classList.add('setActive');
        break;
    }
  }

}
