import { Component, OnInit } from '@angular/core';
import { Restaurant } from '../objects/Restaurant';
import { Food } from '../objects/Food';
import { Profile } from '../objects/Profile';
import { SearchService } from '../services/search.service';
import { Router } from '@angular/router';
import { VoteService } from '../services/vote.service';
import { MapsAPILoader, AgmMarker } from '@agm/core';
import { ProfileService } from '../services/profile.service';
import { MapService } from '../services/map.service';
@Component({
  selector: 'app-search-map',
  templateUrl: './search-map.component.html',
  styleUrls: ['./search-map.component.scss']
})
export class SearchMapComponent implements OnInit {

  foodList: Food[];
  isEmpty: boolean;
  profile: Profile;
  latitude: number;
  longitude: number;
  restaurantList: Restaurant[];
  private geoCoder;
  address: string;
  zoom: number;
  constructor(private searchService: SearchService,
    private router: Router,
    private voteService: VoteService,
    private mapsAPILoader: MapsAPILoader,
    private profileService: ProfileService,
    private mapService: MapService) {

    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
    this.searchService.newRestaurantList.subscribe(data => {
      this.restaurantList = data;
      
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder();
      });
    });
  }


  ngOnInit() {
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        //this.latitude = this.profile.latitude;
        //this.longitude = this.profile.longitude;
        this.latitude = 41.767833;
        this.longitude = -72.672893;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  /*markerDragEnd($event: MouseEvent) {
    console.log($event.);
    $event.
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }*/

  clickedMarker(id) {
    this.searchService.updateSearchMapInfo(id);
  }

}
