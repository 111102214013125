import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Restaurant } from '../objects/Restaurant';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../objects/Profile';
import { Time } from '../objects/Time';
import { ChefCategory } from '../objects/ChefCategory';
import { DropdownItem } from '../objects/responses/DropdownItem';
import { Image } from '../objects/Image';
import { RestaurantBusinessDays } from '../objects/RestaurantBusinessDay';

@Component({
  selector: 'app-restaurantpage',
  templateUrl: './restaurantpage.component.html',
  styleUrls: ['./restaurantpage.component.scss']
})
export class RestaurantpageComponent implements OnInit {
  addedImage: boolean;
  listOfTempImages: any[];
  transferableFiles: string[];
  restaurant: Restaurant;
  restaurants: DropdownItem[];
  currentRestaurant: DropdownItem;
  profile: Profile;
  name: string;
  businessPhone: string;
  websiteURL: string;
  streetNumber: any;
  streetName: any;
  city: any;
  state: any;
  country: any;
  zipCode; number;
  selectedFile: File;
  newPlace: any;
  latitude: number;
  longitude: number;
  category: string;
  businessDays: RestaurantBusinessDays[];
  menuTimes: RestaurantBusinessDays[];
  daysOfTheWeek: string[];
  foodTimes: string[];
  deleteCategories: number[];
  newCategories: string[];
  restaurantCategories: ChefCategory[];
  ownedImages: Image[];
  deletedImages: number[];
  openTime: Time[];
  closeTime: Time[];
  listOfBlobs: any[];

  isAddressInUse: string;
  zoom: number;
  address: string;
  googleAddress: any;
  private geoCoder;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader, private profileService: ProfileService,
    private ngZone: NgZone) {
    this.restaurantCategories = [];
    this.category = '';
    this.name = '';
    this.businessPhone = '';
    this.websiteURL = '';
    this.listOfTempImages = [];
    this.transferableFiles = [];
    this.businessDays = [];
    this.openTime = [];
    this.deleteCategories = [];
    this.newCategories = [];
    this.listOfBlobs = [];
    this.ownedImages = [];
    this.menuTimes = [];
    this.daysOfTheWeek = [];
    this.deletedImages = [];
    this.closeTime = [];
    this.currentRestaurant = { Name: 'none/new', id: -1 };
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
    this.profileService.getRestaurantsByProfile().subscribe(data => {
      data.push(this.currentRestaurant);
      this.restaurants = data;
    });
  }

  ngOnInit() {
    this.setFrontEnd();
    this.profileService.newLocation.subscribe(data => {
      this.isAddressInUse = data;
      if (this.newPlace != null) {
        this.streetNumber = this.newPlace.address_components[0];
        this.streetName = this.newPlace.address_components[1];
        this.city = this.newPlace.address_components[2];
        this.state = this.newPlace.address_components[4];
        this.country = this.newPlace.address_components[5];
        this.zipCode = this.newPlace.address_components[6];
      }
    });

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place);
          this.newPlace = place;
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set longitude, latitude and zoom
          this.longitude = place.geometry.location.lng();
          this.latitude = place.geometry.location.lat();
          this.profileService.CheckLocation(this.longitude, this.latitude);
          this.zoom = 12;
        });
      });
    });
  }

  setFrontEnd() {
    this.daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesay", "Thursday", "Friday", "Saturday"];
    this.foodTimes = ["Breakfast", "Brunch", "Lunch", "Dinner", "Late Night", "Dessert"]
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.businessDays.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.menuTimes.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.menuTimes.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.menuTimes.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.menuTimes.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.menuTimes.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
    this.menuTimes.push({ Enabled: false, openTime: { hour: 0, minute: 0 }, closeTime: { hour: 0, minute: 0 } });
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    console.log(this.listOfTempImages);
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  updateSelection(item) {
    this.currentRestaurant = item;
    this.profileService.getCategoryByID(this.currentRestaurant.id, 'idrestaurant').subscribe(data => {
      this.restaurantCategories = data;
    });
    this.profileService.getRestaurantByID(this.currentRestaurant.id).subscribe(data => {
      this.websiteURL = data.website;
      this.name = data.Name;
      this.businessPhone = data.number;
      this.businessDays = data.daysOfTheWeek;
      this.menuTimes = data.menuTimes;
      this.longitude = data.longitude;
      this.latitude = data.latitude;
      this.ownedImages = data.images;
      if (this.ownedImages.length > 0) {
        this.addedImage = true;
      }
      this.getAddress(this.latitude, this.longitude);
      console.log(data);
    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.googleAddress = results;
          this.streetNumber = results[0].address_components[0].long_name;
          this.streetName = results[0].address_components[1].long_name;
          this.city = results[0].address_components[2].long_name;
          this.state = results[0].address_components[4].long_name;
          this.country = results[0].address_components[5].long_name,
            this.zipCode = results[0].address_components[3].long_name;
          console.log(results);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  onFileChanged(file) {
    this.addedImage = true;
    var i = 0;
    var len = file.target.files.length;

    for (i; i < len; i++) {
      this.selectedFile = file.target.files[i];
      const mimeType = this.selectedFile.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      this.listOfBlobs.push(this.selectedFile);
      this.transferableFiles.push(this.selectedFile.name);

      const reader = new FileReader();
      if (this.selectedFile) {
        reader.readAsDataURL(this.selectedFile);
      } else {
        return;
      }
      reader.onload = (e) => {
        this.listOfTempImages.push((event.target as FileReader).result);
      }
    }
  }

  deleteImage(imageFile: any, isOwned) {
    console.log()
    if (isOwned) {
      // @ts-ignore
      this.deletedImages.push(this.ownedImages.splice(imageFile, 1)[0].idrestaurantimages);
    } else {
      this.listOfTempImages.splice(imageFile, 1);
      this.transferableFiles.splice(imageFile, 1);
    }
  }

  submitChange() {
    if (this.currentRestaurant.id != -1) {
      this.profileService.EditRestaurant(this.currentRestaurant.id,
        this.streetNumber, this.streetName, this.city, this.state, this.country,
        this.zipCode, this.longitude, this.latitude, this.name,
        this.businessPhone, this.websiteURL, this.transferableFiles, this.deleteCategories, this.newCategories, this.deletedImages, this.listOfBlobs, this.businessDays, this.menuTimes);
    }
    //idrestaurant, daysOfTheWeek, openTime, closeTime, categories, email, authID,streetNumber, streetName, city, state, country, zipCode, longitude, latitude, name, phoneNumber, website, images  
    //this.profileService.AddRestaurant(this.profile.email, this.profile.authID, this.streetNumber.long_name, this.streetName.long_name, this.city.long_name, this.state.long_name, this.country.long_name, this.zipCode.long_name, this.longitude, this.latitude, this.profile.userid, this.name, this.businessPhone, this.websiteURL, this.categories, this.transferableFiles, this.businessDays, this.openTime, this.closeTime);
  }

  addCategory(item) {
    this.restaurantCategories.push({ category: this.category, categoryid: -1 });
    this.newCategories.push(this.category);
    this.category = '';
  }

  onKeyDownCategory(event, item) {
    if (event.key === 'Enter') {
      this.addCategory(item);
    }
  }

  removeCategory(index) {
    if (this.restaurantCategories[index].categoryid == -1) {
      this.restaurantCategories.splice(index, 1);
    } else {
      var delObject = this.restaurantCategories[index];
      this.deleteCategories.push(delObject["idcategory"]);
      this.restaurantCategories.splice(index, 1);
      //remove from backend first
    }
  }
}
