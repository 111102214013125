import { Injectable } from '@angular/core';
import { Profile } from '../objects/Profile';
import { BehaviorSubject } from 'rxjs';
import { Restaurant } from '../objects/Restaurant';
import { Food } from '../objects/Food';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  profile: Profile;

  private currFoodList = new BehaviorSubject<Food[]>(null);
  newFoodList = this.currFoodList.asObservable();

  private currRestaurant = new BehaviorSubject<Restaurant>(null);
  newRestauarnt = this.currRestaurant.asObservable();

  constructor(private profileService: ProfileService) {
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
  }

  setData(food: Food[], rest: Restaurant){
    this.currRestaurant.next(rest);
    this.currFoodList.next(food);
  }


}
