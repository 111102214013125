import { Component, OnInit } from '@angular/core';
import { SearchService } from '../services/search.service';
import { Router } from '@angular/router';
import { SearchFilter } from '../objects/searchFilter';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  search: string;
  radius: number;
  filters: SearchFilter;
  constructor(private searchService: SearchService, private router: Router) {
    this.radius = 0;
  }

  ngOnInit() {
    this.searchService.newSearch.subscribe(data => {
      this.search = data;
    });
    this.searchService.newSearchFilters.subscribe(data => {
      this.filters = data;
    });
  }

  updateDropdown(val) {
    this.filters.voteTime = val;
  }

  Search(val) {
    console.log(this.filters);
    if (val == "food") {
      this.searchService.Search("food", this.filters);
      this.router.navigateByUrl('/search-container');
    }
    if (val == "chef") {
      this.searchService.Search(this.search, this.filters);
      this.router.navigateByUrl('/search-container');
    }
    if (val != "food" && val != "chef") {
      this.searchService.Search(this.search, this.filters);
      this.router.navigateByUrl('/search-container');
    }
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.Search("search");
    }
  }

}
