import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ChefsComponent } from './chefs/chefs.component';
import { FoodComponent } from './food/food.component';
import { ProfileComponent } from './profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChefpageComponent } from './chefpage/chefpage.component';
import { FoodpageComponent } from './foodpage/foodpage.component';
import { RestaurantpageComponent } from './restaurantpage/restaurantpage.component';
import { ProfileheaderComponent } from './profileheader/profileheader.component';
import { AgmCoreModule } from '@agm/core';
import { SearchpageComponent } from './searchpage/searchpage.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AuthService } from './services/auth.service';
import { FoodinfoComponent } from './foodinfo/foodinfo.component';
import { ChefinfoComponent } from './chefinfo/chefinfo.component';
import { RestaurantinfoComponent } from './restaurantinfo/restaurantinfo.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgImageSliderModule } from 'ng-image-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { RatingComponent } from './rating/rating.component';
import { SearchContainerComponent } from './search-container/search-container.component';
import { SearchMapComponent } from './search-map/search-map.component';
import { SearchMapInfoComponent } from './search-map-info/search-map-info.component';
import { ProfileinfoComponent } from './profileinfo/profileinfo.component';


@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    HeaderComponent,
    HomeComponent,
    ChefsComponent,
    FoodComponent,
    ProfileComponent,
    ChefpageComponent,
    FoodpageComponent,
    RestaurantpageComponent,
    ProfileheaderComponent,
    SearchpageComponent,
    FoodinfoComponent,
    ChefinfoComponent,
    RestaurantinfoComponent,
    RatingComponent,
    SearchContainerComponent,
    SearchMapComponent,
    SearchMapInfoComponent,
    ProfileinfoComponent,
  ],
  imports: [
    NgbModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBIJORM3jFJbeV3tFPeD_jiT9gw9uYfbew',
      libraries: ["places"]
    }),
    DragDropModule,
    NgImageSliderModule,
    BrowserAnimationsModule,
    MatCheckboxModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
