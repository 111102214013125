import { Component, OnInit } from '@angular/core';
import { FoodService } from '../services/food.service';
import { foodInfoResponse } from '../objects/responses/foodInfoResponse';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Router } from '@angular/router';
import { Food } from '../objects/Food';
@Component({
  selector: 'app-foodinfo',
  templateUrl: './foodinfo.component.html',
  styleUrls: ['./foodinfo.component.scss']
})
export class FoodinfoComponent implements OnInit {
  foodInfo: foodInfoResponse;
  foodIsLiked: boolean;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  constructor(private mapsAPILoader: MapsAPILoader, private foodService: FoodService, private router: Router) { }

  ngOnInit() {

    this.foodService.getFoodInfo(history.state.data.idfood).subscribe(data => {
      this.foodInfo = data;
      console.log(this.foodInfo);
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder();
      });
    });
    this.foodIsLiked = history.state.foodIsLiked;

  }

  routeToRestaurant(foodInfo: Food): void {
    this.router.navigate(['/restaurantinfo'], { state: foodInfo });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = this.foodInfo.latitude;
        this.longitude = this.foodInfo.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  buyCoffee(link) {
    window.open(link, '_blank');
  }

  routeToChefInfo(chefID: number): void {
    this.router.navigate(['/chefinfo'], { state: { data: chefID } });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

}
