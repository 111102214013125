import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss']
})
export class SearchContainerComponent implements OnInit {
  mapView: boolean;
  listView: boolean;
  constructor() {
    this.mapView = false;
    this.listView = true;
  }

  ngOnInit() {

  }

  changeView() {
    if (this.listView == true) {
      this.listView = false;
      this.mapView = true;
    } else {
      this.listView = true;
      this.mapView = false;
    }
  }

}
