import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, zip, from, Observable } from 'rxjs';
import { Profile } from '../objects/Profile';
import { EditProfileResponse } from '../objects/responses/editProfileResponse';
import { Chef } from '../objects/Chef';
import { MenuType } from '../objects/MenuType';
import { PrevTime } from '../objects/responses/PrevTime';
import { RestaurantProfile } from '../objects/RestaurantProfile';
import { GetEditRestaurantResponse } from '../objects/responses/getEditRestaurantResponse';
import { GetEditFoodResponse } from '../objects/responses/GetEditFoodResponse';
import { ChefCategory } from '../objects/ChefCategory';
import { DropdownItem } from '../objects/responses/DropdownItem';
import { ReviewMessage } from '../objects/ReviewMessage';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private updateProfileURL = 'https://matthewmastell.com:3002/updateprofile';
  private checkLocationURL = 'https://matthewmastell.com:3002/checkLocation';
  private addLocationURL = 'https://matthewmastell.com:3002/addRestaurant';
  private editRestaurantURL = 'https://matthewmastell.com:3002/editRestaurant';
  private addFoodItemURL = 'https://matthewmastell.com:3002/addFoodItem';
  private editFoodItemURL = 'https://matthewmastell.com:3002/editFoodItem';
  private getProfile = 'https://matthewmastell.com:3002/getProfile';
  private getChef = 'https://matthewmastell.com:3002/getChefInfo';
  private getMenuTypesURL = 'https://matthewmastell.com:3002/getMenuTypes';
  private getFoodByTimeURL = 'https://matthewmastell.com:3002/getFoodByTime';
  private getRestaurantsByProfileURL = 'https://matthewmastell.com:3002/getRestaurantsByProfile';
  private getRestaurantByIDURL = 'https://matthewmastell.com:3002/getRestaurantByID';
  private getFoodByIDURL = 'https://matthewmastell.com:3002/getFoodByID';
  private getPrevTimesURL = 'https://matthewmastell.com:3002/getPrevTimes';
  private getCategoriesByIdURL = 'https://matthewmastell.com:3002/getCategoriesByID';
  private rateRestaurantURL = 'https://matthewmastell.com:3002/rateRestaurant';
  private getRestaurantReviewsURL = 'https://matthewmastell.com:3002/getRestaurantReviews';

  private profile: Profile;

  private currMessage = new BehaviorSubject<EditProfileResponse | string>({ time: '', message: '' });
  newMessage = this.currMessage.asObservable();

  private newProfile = new BehaviorSubject<Profile>(null);
  currProfile = this.newProfile.asObservable();

  private newChef = new BehaviorSubject<Chef>(null);
  currChef = this.newChef.asObservable();

  private newResponse = new BehaviorSubject<string>("0");
  currResponse = this.newResponse.asObservable();


  //This is used when a restaurant location is being added. We need to make sure we don't have duplicated restaurants o.o
  private currLocationCheck = new BehaviorSubject<string>('');
  newLocation = this.currLocationCheck.asObservable();

  constructor(private http: HttpClient) {
    this.currProfile.subscribe(data => {
      this.profile = data;
    })
  }

  resetValue() {
    this.newResponse.next("0");
  }

  UpdateProfile(firstname, lastname, email, bio, idprofile, UserName, images) {
    const sendData = new FormData();
    sendData.append('firstname', firstname);
    sendData.append('lastname', lastname);
    sendData.append('email', email);
    sendData.append('bio', bio);
    sendData.append('idprofile', idprofile);
    sendData.append('UserName', UserName);
    if (images.length > 0) {
      sendData.append('imageFiles', images[0], images[0].name.substring(0, images[0].name.indexOf('.')));
    }
    this.http.post<EditProfileResponse | string>(this.updateProfileURL, sendData, { responseType: 'json' }).subscribe(data => {
      console.log(data);
      this.currMessage.next(data);
    });
  }

  GetProfile(login, authID) {
    const sendData = new FormData();
    sendData.append('loginName', login);
    sendData.append('authID', authID);
    this.http.post<Profile>(this.getProfile, sendData, { responseType: 'json' }).subscribe(data => {
      navigator.geolocation.getCurrentPosition(showPosition.bind(this));
      function showPosition(position) {
        data.latitude = position.coords.latitude;
        data.longitude = position.coords.longitude;
        this.newProfile.next(data);
      }
    });
  }

  setProfile(profile) {
    this.newProfile.next(profile);
  }

  getMenuTypes(): Observable<DropdownItem[]> {
    return this.http.get<DropdownItem[]>(this.getMenuTypesURL);
  }

  getFoodByTime(): Observable<MenuType[]> {
    return this.http.get<MenuType[]>(this.getFoodByTimeURL);
  }

  getRestaurantsByProfile(): Observable<DropdownItem[]> {
    const sendData = new FormData();
    sendData.append('loginName', this.profile.email);
    sendData.append('authID', this.profile.authID);
    return this.http.post<DropdownItem[]>(this.getRestaurantsByProfileURL, sendData);
  }

  getCategoryByID(id, type): Observable<ChefCategory[]> {
    const sendData = new FormData();
    sendData.append('loginName', this.profile.email);
    sendData.append('authID', this.profile.authID);
    sendData.append('id', id);
    sendData.append('type', type);
    return this.http.post<ChefCategory[]>(this.getCategoriesByIdURL, sendData);
  }

  getFoodByID(id): Observable<GetEditFoodResponse> {
    const sendData = new FormData();
    sendData.append('loginName', this.profile.email);
    sendData.append('authID', this.profile.authID);
    sendData.append('idprofile', this.profile.userid.toString());
    sendData.append('idfood', id);
    return this.http.post<GetEditFoodResponse>(this.getFoodByIDURL, sendData);
  }
  getRestaurantByID(id): Observable<GetEditRestaurantResponse> {
    const sendData = new FormData();
    sendData.append('loginName', this.profile.email);
    sendData.append('authID', this.profile.authID);
    sendData.append('idprofile', this.profile.userid.toString());
    sendData.append('idrestaurant', id);
    return this.http.post<GetEditRestaurantResponse>(this.getRestaurantByIDURL, sendData);
  }

  getPrevTime(): Observable<PrevTime> {
    const sendData = new FormData();
    sendData.append('loginName', this.profile.email);
    sendData.append('authID', this.profile.authID);
    return this.http.post<PrevTime>(this.getPrevTimesURL, sendData);
  }

  getChefInfo(idchef): Observable<Chef> {
    const sendData = new FormData();
    sendData.append('idchef', idchef);
    return this.http.post<Chef>(this.getChef, sendData);
  }

  getRestaurantReviews(idrestaurant): Observable<ReviewMessage[]> {
    const sendData = new FormData();
    sendData.append('idrestaurant', idrestaurant);
    return this.http.post<ReviewMessage[]>(this.getRestaurantReviewsURL, sendData);
  }

  EditFoodItem(email, authID, name, idrestaurant, idprofile, newCategories, deleteCategories, idfood, price, description, cal, transferableFiles, deletedImages, vibrantList, foodTimes, images) {
    const sendData = new FormData();
    sendData.append('loginName', email);
    sendData.append('authID', authID);
    sendData.append('name', name);
    sendData.append('idfood', idfood);
    sendData.append('idrestaurant', idrestaurant);
    sendData.append('idprofile', idprofile);
    sendData.append('newCategories', JSON.stringify(newCategories));
    sendData.append('deleteCategories', JSON.stringify(deleteCategories));
    sendData.append('images', JSON.stringify(transferableFiles));
    sendData.append('vibrantList', JSON.stringify(vibrantList));
    sendData.append('price', price);
    sendData.append('description', description);
    sendData.append('calories', cal);
    sendData.append('deletedImages', deletedImages);
    sendData.append('foodTimes', JSON.stringify(foodTimes));
    for (var i = 0, len = transferableFiles.length; i < len; i++) {
      sendData.append('imageFiles', images[i], images[i].name.substring(0, images[i].name.indexOf('.')));
    }
    this.http.post(this.editFoodItemURL, sendData, { responseType: 'text' }).subscribe(data => {
      this.newResponse.next(data);
    });
  }

  AddFoodItem(email, authID, name, idrestaurant, idprofile, categories, transferableFiles, vibrantList, price, description, cal, images) {
    const sendData = new FormData();
    sendData.append('loginName', email);
    sendData.append('authID', authID);
    sendData.append('name', name);
    sendData.append('idrestaurant', idrestaurant);
    sendData.append('idprofile', idprofile);
    sendData.append('categories', JSON.stringify(categories));
    sendData.append('images', JSON.stringify(transferableFiles));
    sendData.append('vibrantList', JSON.stringify(vibrantList));
    sendData.append('price', price);
    sendData.append('description', description);
    sendData.append('calories', cal);
    sendData.append('imageFiles', images);
    for (var i = 0, len = transferableFiles.length; i < len; i++) {
      console.log(images[i]);
      sendData.append('imageFiles', images[i], images[i].name.substring(0, images[i].name.indexOf('.')));
    }
    this.http.post(this.addFoodItemURL, sendData, { responseType: 'text' }).subscribe(data => {
      this.newResponse.next(data);
    });
  }

  CheckLocation(long, lat) {
    const sendData = new FormData();
    console.log(long, lat);
    sendData.append('long', this.round(long, 6).toString());
    sendData.append('lat', this.round(lat, 6).toString());

    this.http.post(this.checkLocationURL, sendData, { responseType: 'text' }).subscribe(data => {
      this.currLocationCheck.next(data);
    });
  }

  RateRestaurant(vote, idrestaurant, message) {
    const sendData = new FormData();
    sendData.append('email', this.profile.email);
    sendData.append('idprofile', this.profile.userid.toString());
    sendData.append('idrestaurant', idrestaurant);
    sendData.append('authID', this.profile.authID);
    sendData.append('vote', vote);
    sendData.append('voteDescription', message);

    this.http.post(this.rateRestaurantURL, sendData, { responseType: 'text' }).subscribe(data => {
      console.log(data);
    });
  }

  EditRestaurant(idrestaurant, streetNumber, streetName, city, state, country, zipCode, longitude, latitude, name, phoneNumber, website, transferableFiles, deleteCategories, newCategories, deletedImages, images, businessDays, menuTimes) {
    const sendData = new FormData();
    sendData.append('loginName', this.profile.email);
    sendData.append('idprofile', this.profile.userid.toString());
    sendData.append('authID', this.profile.authID);
    sendData.append('idrestaurant', idrestaurant);
    sendData.append('businessDays', JSON.stringify(businessDays));
    sendData.append('menuTimes', JSON.stringify(menuTimes));
    sendData.append('streetNumber', streetNumber);
    sendData.append('streetName', streetName);
    sendData.append('city', city);
    sendData.append('state', state);
    sendData.append('country', country);
    sendData.append('zipCode', zipCode);
    sendData.append('long', this.round(longitude, 6).toString());
    sendData.append('lat', this.round(latitude, 6).toString());
    sendData.append('name', name);
    sendData.append('businessPhone', phoneNumber);
    sendData.append('website', website);
    sendData.append('newCategories', JSON.stringify(newCategories));
    sendData.append('deleteCategories', JSON.stringify(deleteCategories));
    sendData.append('images', JSON.stringify(transferableFiles));
    sendData.append('deletedImages', deletedImages);
    for (var i = 0, len = transferableFiles.length; i < len; i++) {
      sendData.append('imageFiles', images[i], images[i].name.substring(0, images[i].name.indexOf('.')));
    }

    this.http.post(this.editRestaurantURL, sendData, { responseType: 'text' }).subscribe(data => {
      console.log(data);
    });
  }

  AddRestaurant(email, authid, streetNumber, streetName, city, state, country, zipCode, long, lat, id, name, phoneNumber, website, categories, images, businessDays, openTime, closeTime) {
    const sendData = new FormData();

    sendData.append('email', email);
    sendData.append('authID', authid);
    sendData.append('streetNumber', streetNumber);
    sendData.append('streetName', streetName);
    sendData.append('city', city);
    sendData.append('state', state);
    sendData.append('country', country);
    sendData.append('zipCode', zipCode);
    sendData.append('long', this.round(long, 6).toString());
    sendData.append('lat', this.round(lat, 6).toString());
    sendData.append('idprofile', id);
    sendData.append('name', name);
    sendData.append('businessPhone', phoneNumber);
    sendData.append('website', website);
    sendData.append('categories', JSON.stringify(categories));
    sendData.append('images', JSON.stringify(images));
    sendData.append('businessDays', JSON.stringify(businessDays));
    sendData.append('openTime', JSON.stringify(openTime));
    sendData.append('closeTime', JSON.stringify(closeTime));

    this.http.post(this.addLocationURL, sendData, { responseType: 'text' }).subscribe(data => {
      console.log(data);
      this.currLocationCheck.next(data);
    });
  }

  round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}