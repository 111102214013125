import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../objects/Profile';
@Injectable({
  providedIn: 'root'
})
export class VoteService {

  private voteFoodURL = 'https://matthewmastell.com:3002/vote';
  profile: Profile;

  constructor(private http: HttpClient, private profileService: ProfileService) {
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
  }

  vote(idfood) {
    const sendData = new FormData();
    sendData.append('email', this.profile.email);
    sendData.append('authID', this.profile.authID);
    sendData.append('idprofile', this.profile.userid.toString());
    sendData.append('idfood', idfood);
    this.http.post(this.voteFoodURL, sendData, { responseType: 'json' }).subscribe(data => {
      console.log(data);
    });
  }
}
