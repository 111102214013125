import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Food } from '../objects/Food';
import { BehaviorSubject, Observable } from 'rxjs';
import { Profile } from '../objects/Profile';
import { ProfileService } from './profile.service';
import { foodInfoResponse } from '../objects/responses/foodInfoResponse';
import { Image } from '../objects/Image';
import { FoodByRestaurant } from '../objects/responses/foodByRestaurant';

@Injectable({
  providedIn: 'root'
})
export class FoodService {

  profile: Profile;

  private currFoodList = new BehaviorSubject<Food[]>(null);
  newFoodList = this.currFoodList.asObservable();

  private currFoodInfo = new BehaviorSubject<foodInfoResponse>(null);
  newFoodInfo = this.currFoodInfo.asObservable();

  private currRestaurantInfo = new BehaviorSubject<foodInfoResponse>(null);
  newRestaurantInfo = this.currRestaurantInfo.asObservable();

  private getFoodUrl = 'https://matthewmastell.com:3002/getFood';
  private getFoodInfoUrl = 'https://matthewmastell.com:3002/getFoodInfo';
  private getFoodbyRestaurantUrl = 'https://matthewmastell.com:3002/getFoodbyRestaurant';
  private getFoodbyRestaurantImagesUrl = 'https://matthewmastell.com:3002/getRestaurantImages';

  constructor(private http: HttpClient, private profileService: ProfileService) {
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
  }

  getFoods() {
    this.http.get<Food[]>(this.getFoodUrl).subscribe(data => {
      var i = 0;
      var len = data.length;
      for (i; i < len; i++) {
        data[i].distance = this.distance(data[i].latitude, data[i].longitude, this.profile.latitude, this.profile.longitude, "F");
      };

      this.currFoodList.next(this.compareFoods(data));
    });
  }

  getFoodbyRestaurant(idrestaurant, isTimeSensitive, idmenuOption): Observable<FoodByRestaurant> {
    const sendData = new FormData();
    var d = new Date();
    var time = "";
    var day = "" + d.getDay();

    if (d.getHours() < 10) {
      time = "0" + d.getHours();
    } else {
      time = "" + d.getHours();
    }

    if (d.getMinutes() < 10) {
      time += "0" + d.getMinutes();
    } else {
      time += d.getMinutes();
    }

    sendData.append('idrestaurant', idrestaurant);
    sendData.append('isTimeSensitive', isTimeSensitive);
    sendData.append('userTime', time);
    sendData.append('dayOfTheWeek', day);
    sendData.append('idmenuOption', idmenuOption);
    return this.http.post<FoodByRestaurant>(this.getFoodbyRestaurantUrl, sendData);
  }

  getRestaurantImages(idrestaurant): Observable<Image[]> {
    const sendData = new FormData();
    sendData.append('idrestaurant', idrestaurant);
    return this.http.post<Image[]>(this.getFoodbyRestaurantImagesUrl, sendData);
  }

  getFoodInfo(idfood): Observable<foodInfoResponse> {
    const sendData = new FormData();
    sendData.append('idfood', idfood);
    return this.http.post<foodInfoResponse>(this.getFoodInfoUrl, sendData);
  }

  //Compare the foods listed with the user profile
  compareFoods(foodlist) {
    let i;
    let j;
    let foodLen;
    let profileFoodLen;
    if (this.profile.foodlikes) {
      for (i = 0, foodLen = foodlist.length; i < foodLen; i++) {
        foodlist[i].isfoodliked = false;
        for (j = 0, profileFoodLen = this.profile.foodlikes.length; j < profileFoodLen; j++) {
          if (Number(this.profile.foodlikes[j].idfood) === Number(foodlist[i].idfood)) {
            foodlist[i].isfoodliked = true;
          }
        }
      }
    }
    return foodlist;
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var radlon1 = Math.PI * lon1 / 180
    var radlon2 = Math.PI * lon2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return Math.floor(dist);
  }
}
