import { Component, OnInit } from '@angular/core';
import { Food } from '../objects/Food';
import { Profile } from '../objects/Profile';
import { Time } from '../objects/Time'
import { ProfileService } from '../services/profile.service';
// @ts-ignore
import Vibrant from 'node-vibrant';
import { ImageTransfer } from '../objects/imageTransfer';
import { ChefCategory } from '../objects/ChefCategory';
import { DropdownItem } from '../objects/responses/DropdownItem';
import { Image } from '../objects/Image';
import { EditFood } from '../objects/EditFood';

@Component({
  selector: 'app-foodpage',
  templateUrl: './foodpage.component.html',
  styleUrls: ['./foodpage.component.scss']
})
export class FoodpageComponent implements OnInit {

  addedImage: boolean;
  profile: Profile;
  listOfTempImages: any[];
  listOfBlobs: any[];
  transferableFiles: string[];
  vibrantList: string[];
  foodItem: EditFood;
  name: string;
  response: string;
  price: number;
  foodCategories: ChefCategory[];
  restaurants: DropdownItem[];
  owner: boolean;
  selectedFile: File;
  cal: number;
  category: string;
  description: string;
  foods: DropdownItem[];
  currentFood: DropdownItem;
  currentRestaurant: DropdownItem;
  currentMenuType: DropdownItem;
  menuTypes: DropdownItem[];
  ownedImages: Image[];
  deletedImages: number[];

  deleteCategories: number[];
  newCategories: string[];

  constructor(private profileService: ProfileService) {
    this.addedImage = false;
    this.listOfTempImages = [];
    this.transferableFiles = [];
    this.listOfBlobs = [];
    this.vibrantList = [];
    this.owner = false;
    this.category = '';
    this.restaurants = [];
    this.response = "0";
    this.menuTypes = [];
    this.newCategories = [];
    this.ownedImages = [];
    this.deleteCategories = [];
    this.deletedImages = [];
    this.foodItem = {
      calories: 0,
      price: 0,
      foodName: "",
      description: "",
      foodCategories: [],
      ownedImages: [],
      foodTime: [{ Day: "Sunday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false },
      { Day: "Monday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false },
      { Day: "Tuesday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false },
      { Day: "Wednesday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false },
      { Day: "Thursday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false },
      { Day: "Friday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false },
      { Day: "Saturday", Breakfast: false, Brunch: false, Lunch: false, Dinner: false, LateNight: false, Dessert: false }],
    };
    this.currentFood = { Name: 'None/new', id: -1 };
    this.currentRestaurant = { Name: 'Select a restaurant', id: -1 };
    this.currentMenuType = { Name: 'Choose a time to eat', id: -1 };
  }

  ngOnInit() {
    this.profileService.currResponse.subscribe(data => {
      if (data != "0") {
        if (data != 'Inserted Food with images') {
          this.response = "2";
        } else {
          this.response = "1";
        }
      } else {
        this.response = "0";
      }
    });

    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
      this.restaurants = this.profile.restaurants;
      this.foods = this.profile.foods;
    });

    this.profileService.getMenuTypes().subscribe(data => {
      this.menuTypes = data;
    });

  }

  updateSelection(item) {
    this.currentFood = item;
    this.profileService.getCategoryByID(this.currentFood.id, 'idfood').subscribe(data => {
      this.foodItem.foodCategories = data;
    });

    this.profileService.getFoodByID(this.currentFood.id).subscribe(data => {
      this.foodItem.description = data.description;
      this.foodItem.foodName = data.Name;
      this.foodItem.price = data.price;
      this.foodItem.calories = data.calories;
      this.foodItem.ownedImages = data.images;
      this.foodItem.foodTime = data.Times;
      if (this.foodItem.ownedImages.length > 0) {
        this.addedImage = true;
      }

      this.restaurants.forEach(el => {
        if (el.id = data.idrestaurant) {
          this.currentRestaurant = el;
        }
      })
    });
  }

  ngOnDestroy() {
    this.profileService.resetValue();
  }

  onFileChanged(file) {
    this.addedImage = true;
    var i = 0;
    var len = file.target.files.length;
    for (i; i < len; i++) {
      this.selectedFile = file.target.files[i];
      const mimeType = this.selectedFile.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      this.listOfBlobs.push(this.selectedFile);
      this.transferableFiles.push(this.selectedFile.name);

      const reader = new FileReader();
      if (this.selectedFile) {
        reader.readAsDataURL(this.selectedFile);
      } else {
        return;
      }
      reader.onload = (e) => {
        // @ts-ignore
        Vibrant.from(e.target.result).getSwatches((err, swatch) => {
          this.vibrantList.push(swatch.Muted.hex);
        });
        this.listOfTempImages.push((event.target as FileReader).result);
      }
    }

  }

  addCategory(item) {
    this.foodCategories.push({ category: this.category, categoryid: -1 });
    this.newCategories.push(this.category);
    this.category = '';
  }

  onKeyDownCategory(event, item) {
    if (event.key === 'Enter') {
      this.addCategory(item);
    }
  }

  removeCategory(index) {
    if (this.foodCategories[index].categoryid == -1) {
      this.foodCategories.splice(index, 1);
    } else {
      var delObject = this.foodCategories[index];
      this.deleteCategories.push(delObject["idcategory"]);
      this.foodCategories.splice(index, 1);
    }
  }

  updateMenuType(item) {
    this.currentMenuType = item;
  }

  AddFoodItem() {
    if (this.currentFood.id != -1) {
      this.profileService.EditFoodItem(this.profile.email, this.profile.authID, this.foodItem.foodName, this.currentRestaurant.id, this.profile.userid, this.newCategories, this.deleteCategories, this.currentFood.id, this.foodItem.price, this.foodItem.description, this.foodItem.calories, this.transferableFiles, this.deletedImages, this.vibrantList, this.foodItem.foodTime, this.listOfBlobs);
    } else {
      this.profileService.AddFoodItem(this.profile.email, this.profile.authID, this.foodItem.foodName, this.currentRestaurant.id, this.profile.userid, this.newCategories, this.transferableFiles, this.vibrantList, this.foodItem.price, this.foodItem.description, this.foodItem.calories, this.listOfBlobs);
    }
  }

  deleteImage(imageFile: number, isOwned) {
    if (isOwned) {
      // @ts-ignore
      this.deletedImages.push(this.foodItem.ownedImages.splice(imageFile, 1)[0].idfoodimages);
    } else {
      this.listOfTempImages.splice(imageFile, 1);
      this.transferableFiles.splice(imageFile, 1);
      this.vibrantList.splice(imageFile, 1);
    }
  }

}
