import { Component, OnInit } from '@angular/core';
import { Profile } from '../objects/Profile';
import { ProfileService } from '../services/profile.service';
import { EditProfileResponse } from '../objects/responses/editProfileResponse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: Profile;
  tempImgUrl: any;
  selectedFile: File;
  showResult: boolean;
  listOfBlobs: any[];
  //This is only for local development.
  imageSelected: string;
  result: EditProfileResponse;
  changingImage: boolean;

  constructor(private profileService: ProfileService, private router: Router) {
    this.changingImage = false;
    this.showResult = false;
    this.listOfBlobs = [];
  }

  ngOnInit() {
    this.profileService.currProfile.subscribe(data => {
      this.profile = data;
    });
    this.profileService.newMessage.subscribe(data => {
      // @ts-ignore
      this.result = data;
    });
  }

  // This presents the image file that will be uploaded using a false path.
  onFileChanged(file) {
    this.changingImage = true;
    this.selectedFile = file.target.files[0];
    this.imageSelected = this.selectedFile.name;
    if (this.listOfBlobs.length == 1) {
      this.listOfBlobs[0] = this.selectedFile;
    } else {
      this.listOfBlobs.push(this.selectedFile);
    }

    const mimeType = this.selectedFile.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    if (this.selectedFile) {
      reader.readAsDataURL(this.selectedFile);
    } else {
      return;
    }
    reader.onload = (e) => {
      this.tempImgUrl = reader.result;
    }
  }

  submitChange() {
    this.showResult = true;
    //separate saving of file and profile info
    this.profileService.UpdateProfile(this.profile.firstname, this.profile.lastname, this.profile.email, this.profile.bio, this.profile.userid, this.profile.UserName, this.listOfBlobs);
  }
}
